const { default: CreateMuiTheme } = require('@material-ui/core/styles/createMuiTheme');

// The object below overrides specific values and/or extends the default material-ui theme, which can currently be found here:
// https://material-ui.com/customization/default-theme/#default-theme
// Any of the values in that default theme may be used throughout this project, even if they aren't explicitly defined here
// (e.g., `theme.shape.borderRadius` will return 4, even though `shape` isn't currently defined in this file)

const systemFontStack = '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif';

const steelGray = '#1B1B28';
const fjord = '#434d6b';
const ochre = '#ffb822';
const promericaBlue = '#1DA7DF';

module.exports = CreateMuiTheme({
    typography: {
        htmlFontSize: 15,
        fontSize: 15,
        fontFamily: ['Poppins', systemFontStack].join(','),
        h1: {
            fontWeight: 500
        },
        h2: {
            fontWeight: 500
        },
        h3: {
            fontWeight: 500
        },
        h4: {
            fontWeight: 500
        },
        h5: {
            fontWeight: 500
        },
        h6: {
            fontWeight: 500
        },
        body1: {
            fontSize: '1.1rem'
        }
    },

    palette: {
        contrastThreshold: 2,
        primary: {
            // light: will be calculated from palette.primary.main,
            main: '#00A7E1'
            // dark: will be calculated from palette.primary.main,
            // contrastText: will be calculated to contrast with palette.primary.main
        },
        secondary: {
            main: '#0abb87',
            contrastText: '#ffffff'
        },
        text: {
            primary: '#48465b'
        },
        error: {
            main: '#fd3a3a'
        },
        background: {
            paleGray: '#f2f4f9',
            mediumGray: '#eee'
        },
        steelGray: {
            main: steelGray,
            light: '#43434f'
        },
        fjord: {
            main: fjord
        },
        ochre: {
            main: ochre,
            dark: '#b28017',
            light: '#ffca57',
            contrastText: '#000'
        },
        promericaBlue: {
            main: promericaBlue
        }
    },

    /**
       * @see https://material-ui.com/customization/globals/#default-props
       */
    props: {
        // Name of the component ⚛️
        MuiButtonBase: {
            // The properties to apply
            disableRipple: true // No more ripple, on the whole application 💣!
        },

        // Set default elevation to 1 for popovers.
        MuiPopover: {
            elevation: 1
        },

        MuiTextField: {
            type: 'text',
            variant: 'outlined',
            size: 'small',
            margin: 'dense'
        },

        MuiFormControl: {
            margin: 'dense'
        },

        MuiSelect: {
            variant: 'outlined'
        }
    },

    overrides: {
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: '#1a1a27' // aka brand-dark
            }
        },
        MuiDrawer: {
            paper: {
                backgroundColor: steelGray,
                color: '#a2a3b7',
                width: 275
            }
        },
        MuiButton: {
            root: {
                textTransform: 'capitalize',
                margin: 8
            },
            contained: {
                backgroundColor: '#606060',
                color: 'white',
                '&:hover': {
                    backgroundColor: '#363636'
                },
                '&:focus': {
                    backgroundColor: '#363636'
                }
            }
        },
        MuiTypography: {
            gutterBottom: {
                marginBottom: '1rem'
            }
        },
        MuiTableHead: {
            root: {
                backgroundColor: '#f2f4f9'
            }
        },
        MuiTableCell: {
            root: {
                borderBottomWidth: 0
            }
        },
        MuiCssBaseline: {
            '@global': {
                html: {
                    fontSize: 15
                }
            }
        }
    }
});
