const React = require('react');
const T = require('prop-types');
const { NavLink } = require('react-router-dom');
const { default: Styled } = require('styled-components');
const { default: Drawer } = require('@material-ui/core/Drawer');
const { default: List } = require('@material-ui/core/List');
const { default: ListItem } = require('@material-ui/core/ListItem');
const { default: ListItemIcon } = require('@material-ui/core/ListItemIcon');
const { default: ListItemText } = require('@material-ui/core/ListItemText');
const { default: AppBar } = require('@material-ui/core/AppBar');
const { default: Toolbar } = require('@material-ui/core/Toolbar');
const { default: HomeIcon } = require('@material-ui/icons/Home');
const { default: UserIcon } = require('@material-ui/icons/Group');
const { default: ClientIcon } = require('@material-ui/icons/Domain');
const { default: ProgramIcon } = require('@material-ui/icons/Assignment');
const { default: LocalHospitalIcon } = require('@material-ui/icons/LocalHospital');
const { default: ListAltIcon } = require('@material-ui/icons/ListAlt');
const ActiveProgramDisplay = require('./ActiveProgramDisplay');
const { default: LogoImage } = require('../img/logo-light.svg');
const Theme = require('../theme');
const Constants = require('../utils/constants');

const internals = {};

module.exports = (props) => {

    const { Link, NestedListItem, StyledListItemIcon, SiteTitle, SiteLogo, EnvironmentBanner } = internals;
    const { activeProgram, isPermanent, role } = props;
    const isSuperAdmin = role === Constants.ROLE.SUPERADMIN;
    const isProduction = Constants.ENVIRONMENT === 'production';

    return (
        <Drawer
            open={props.isDrawerOpen}
            onClose={props.handleToggleDrawer}
            variant={isPermanent ? 'permanent' : 'temporary'}
            style={{
                width: `${Theme.overrides.MuiDrawer.paper.width}px`
            }}
            PaperProps={{
                style: { border: 'none' }
            }}
        >
            {isPermanent &&
                <AppBar position='sticky' elevation={0} style={{ background: isProduction ? 'inherit' : Theme.palette.error.main }}>
                    <Toolbar variant='dense' disableGutters>
                        <SiteTitle to='/'>
                            {isProduction && (
                                <SiteLogo src={LogoImage} alt='Promerica Logo' />
                            )}
                            {!isProduction && (
                                <EnvironmentBanner>Promerica {Constants.ENVIRONMENT}</EnvironmentBanner>
                            )}
                        </SiteTitle>
                    </Toolbar>
                </AppBar>}
            <List component='nav'>
                <Link component={NavLink} exact to='/'>
                    <StyledListItemIcon>
                        <HomeIcon />
                    </StyledListItemIcon>
                    <ListItemText primary='Home' />
                </Link>
                {isSuperAdmin &&
                <Link component={NavLink} exact to='/users/search'>
                    <StyledListItemIcon>
                        <UserIcon />
                    </StyledListItemIcon>
                    <ListItemText primary='Users' />
                </Link>}
                {isSuperAdmin &&
                <Link component={NavLink} exact to='/screening-metrics'>
                    <StyledListItemIcon>
                        <LocalHospitalIcon />
                    </StyledListItemIcon>
                    <ListItemText primary='Screening Metrics' />
                </Link>}
                <Link component={NavLink} exact to='/forms'>
                    <StyledListItemIcon>
                        <ListAltIcon />
                    </StyledListItemIcon>
                    <ListItemText primary='Forms' />
                </Link>
                {isSuperAdmin &&
                <Link component={NavLink} exact to='/clients/search'>
                    <StyledListItemIcon>
                        <ClientIcon />
                    </StyledListItemIcon>
                    <ListItemText primary='Clients' />
                </Link>}
                <Link component={NavLink} exact to='/programs/search'>
                    <StyledListItemIcon>
                        <ProgramIcon />
                    </StyledListItemIcon>
                    <ListItemText primary='Programs' />
                </Link>
                {activeProgram &&
                    <>
                        <ActiveProgramDisplay program={activeProgram} />
                        <NestedListItem to={`/programs/${activeProgram.id}/setup/forms`}>
                            <ListItemText primary='Upload Forms' />
                        </NestedListItem>
                        {isSuperAdmin &&
                            <NestedListItem to={`/programs/${activeProgram.id}/setup/eligibility`}>
                                <ListItemText primary='Eligibility Mapping' />
                            </NestedListItem>}
                        <NestedListItem to={`/programs/${activeProgram.id}/setup/patient-signup-text`}>
                            <ListItemText primary='Portal Content' />
                        </NestedListItem>
                        <NestedListItem to={`/programs/${activeProgram.id}/setup/email`}>
                            <ListItemText primary='Email Content' />
                        </NestedListItem>
                        <NestedListItem to={`/programs/${activeProgram.id}/setup/sms`}>
                            <ListItemText primary='SMS Content' />
                        </NestedListItem>
                        <NestedListItem to={`/programs/${activeProgram.id}/setup/screening-metrics`}>
                            <ListItemText primary='Screening Metrics' />
                        </NestedListItem>
                        <NestedListItem to={`/programs/${activeProgram.id}/events/search`}>
                            <ListItemText primary='Search Events' />
                        </NestedListItem>
                        <NestedListItem to={`/programs/${activeProgram.id}/screening/search`}>
                            <ListItemText primary='Search Patients' />
                        </NestedListItem>
                        <NestedListItem to={`/programs/${activeProgram.id}/email-results`}>
                            <ListItemText primary='Email Screening Results' />
                        </NestedListItem>
                        <NestedListItem to={`/programs/${activeProgram.id}/reporting`}>
                            <ListItemText primary='Reporting' />
                        </NestedListItem>
                    </>}
            </List>
        </Drawer>
    );
};

module.exports.propTypes = {
    handleToggleDrawer: T.func,
    activeProgram: T.object,
    isDrawerOpen: T.bool,
    isPermanent: T.bool,
    role: T.string
};

internals.StyledListItemIcon = Styled(ListItemIcon)`
    min-width: 0;
    width: ${({ theme }) => theme.spacing(5)}px;
    color: ${({ theme }) => theme.palette.fjord.main};
`;

internals.Link = Styled(ListItem).attrs({ color: 'inherit' })`
    color: inherit;
    transition: ${({ theme }) => `all ${theme.transitions.easing.easeInOut} ${theme.transitions.duration.standard}`};

    &.active,
    &:hover,
    &:focus {
        color: ${({ theme }) => theme.palette.common.white};
        background-color: ${({ theme }) => theme.palette.primary.main};

        ${internals.StyledListItemIcon} {
            color: ${({ theme }) => theme.palette.common.white};
        }
    }
`;

internals.NestedListItem = Styled(internals.Link).attrs({ component: NavLink })`
    padding-left: ${({ theme }) => theme.spacing(7)}px;

    &:before {
        content: '';
        position: absolute;
        top: calc(50% - 2px);
        left: ${({ theme }) => theme.spacing(3)}px;
        width: 4px;
        height: 4px;
        border-radius: 2px;
        background: ${({ theme }) => theme.palette.primary.main};
    }

    &.active,
    &:hover,
    &:focus {
        background-color: ${({ theme }) => theme.palette.primary.main};

        &:before {
            background: currentColor;
        }
    }
`;

internals.SiteTitle = Styled(NavLink)`
    flex-grow: 1;
    text-decoration: none;
`;

internals.SiteLogo = Styled.img`
    display: block;
    height: 30px;
    margin-left: ${({ theme }) => theme.spacing(2)}px;
`;

internals.EnvironmentBanner = Styled.div`
    margin-left: ${({ theme }) => theme.spacing(2)}px;
    font-size: ${({ theme }) => theme.spacing(2)}px;
    text-transform: uppercase;
    text-decoration: none;
    color: white;
`;
