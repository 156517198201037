const QueryString = require('querystring');
const MiddleEnd = require('strange-middle-end');
const WebClient = require('../../utils/web-client');
const Schema = require('./schema');
const M = require('../');
const {
    CREATE_USER,
    FETCH_USER,
    FETCH_USERS,
    EDIT_USER,
    DELETE_USER,
    CREATE_CLIENT,
    EDIT_CLIENT,
    FETCH_CLIENT,
    FETCH_CLIENTS,
    UPLOAD_CLIENT_LOGO,
    DELETE_CLIENT,
    ARCHIVE_CLIENT,
    CREATE_PROGRAM,
    FETCH_PROGRAM,
    FETCH_PROGRAMS,
    DELETE_PROGRAM,
    EDIT_PROGRAM,
    ARCHIVE_PROGRAM,
    UPLOAD_ELIGIBILITY,
    FETCH_ELIGIBILITY_FILES,
    UPDATE_ELIGIBILTY_MAPPING,
    FETCH_ELIGIBILITY_MAPPING,
    UPLOAD_CONSENT,
    UPLOAD_EVENTS,
    UPLOAD_PDFFORM,
    UPLOAD_COVIDFORM,
    UPLOAD_COVID_REQUISITION,
    UPLOAD_APPOINTMENTS,
    FETCH_SCREENING_METRICS,
    UPDATE_SCREENING_METRICS_SORT,
    FETCH_SCREENING_CATEGORIES,
    EDIT_SCREENING_METRICS,
    SEARCH_PATIENTS,
    FETCH_PATIENT,
    CREATE_PATIENT,
    UPDATE_PATIENT,
    FETCH_SCREENING,
    EDIT_SCREENING,
    CONFIRM_SCREENING,
    CREATE_UNLINKED_SCREENING,
    FETCH_EVENTS,
    FETCH_ALL_EVENTS,
    FETCH_APPOINTMENT,
    UPDATE_APPOINTMENT,
    SCHEDULE_APPOINTMENT,
    RESCHEDULE_APPOINTMENT,
    CANCEL_APPOINTMENT,
    SEND_CONFIRMATION,
    FETCH_EVENT,
    EDIT_EVENT,
    DELETE_EVENT,
    SEND_EVENT_COMMUNICATION,
    FETCH_CLIENT_REPORT,
    FETCH_PARTICIPATION_REPORT,
    FETCH_SCREENINGS_WITH_RESULTS,
    FETCH_ALL_APPOINTMENTS_FOR_EVENT,
    SEND_RESULTS_EMAILS,
    PURGE_DATA,
    UPSERT_FORM,
    FETCH_FORMS,
    FETCH_FORM,
    DELETE_FORM,
    UPSERT_FORMSUBMISSION,
    FETCH_FORMSUBMISSION,
    FETCH_FORMSUBMISSIONS
} = require('./action-types');

const internals = {};

exports.createUser = MiddleEnd.createAction(CREATE_USER, {
    index: true,
    handler: async (userInfo) => {

        const { data: { results } } = await WebClient.post('/auth/invite', userInfo);
        return results;
    }
});

exports.createClient = MiddleEnd.createAction(CREATE_CLIENT, {
    index: true,
    schema: Schema.client,
    handler: async (client) => {

        const { data: { results } } = await WebClient.put('/clients', client);

        return results;
    }
});

exports.fetchUser = MiddleEnd.createAction(FETCH_USER, {
    index: true,
    schema: Schema.user,
    handler: async (id) => {

        const { data: { results } } = await WebClient.get(`/users/${id}`);
        return results;
    }
});

exports.fetchUsers = MiddleEnd.createAction(FETCH_USERS, {
    index: true,
    schema: [Schema.user],
    handler: async () => {

        const { data: { results } } = await WebClient.get('/users');
        return results;
    }
});

exports.editUser = MiddleEnd.createAction(EDIT_USER, {
    index: true,
    schema: Schema.user,
    handler: async (userInfo) => {

        const { data: { results } } = await WebClient.post('/auth/user', userInfo);
        return results;
    }
});

exports.deleteUser = MiddleEnd.createAction(DELETE_USER, {
    index: true,
    handler: async (id) => {

        const { data: { results } } = await WebClient.delete(`/users/${id}`);
        return results;
    }
});

exports.editClient = MiddleEnd.createAction(EDIT_CLIENT, {
    index: true,
    schema: Schema.client,
    handler: async (clientInfo) => {

        const { data: { results } } = await WebClient.post('/clients', clientInfo);
        return results;
    }
});

exports.fetchClient = MiddleEnd.createAction(FETCH_CLIENT, {
    index: true,
    schema: Schema.client,
    handler: async (id) => {

        const { data: { results } } = await WebClient.get(`/clients/${id}`);
        return results;
    }
});

exports.deleteClient = MiddleEnd.createAction(DELETE_CLIENT, {
    index: true,
    handler: async (id) => {

        const { data: { results } } = await WebClient.delete(`/clients/${id}`);
        return results;
    },
    after: () => M.dispatch.model.fetchClients()
});

exports.archiveClient = MiddleEnd.createAction(ARCHIVE_CLIENT, {
    index: true,
    schema: Schema.client,
    handler: async (id) => {

        const { data: { results } } = await WebClient.post(`/clients/archive`, { id });
        return results;
    }
});

exports.fetchClients = MiddleEnd.createAction(FETCH_CLIENTS, {
    index: true,
    schema: [Schema.client],
    handler: async () => {

        const { data: { results } } = await WebClient.get('/clients');
        return results;
    }
});

exports.uploadClientLogo = MiddleEnd.createAction(UPLOAD_CLIENT_LOGO, {
    indexed: true,
    handler: async (file) => {

        const { data: { results } } = await WebClient.put('assets', file, { headers: { 'x-filename': file.name, 'x-filetype': file.type } });

        return results.Location;
    }
});

exports.createProgram = MiddleEnd.createAction(CREATE_PROGRAM, {
    index: true,
    handler: async (programInfo) => {

        const { data: { results } } = await WebClient.put('/programs', programInfo);
        return results;
    }
});

exports.fetchProgram = MiddleEnd.createAction(FETCH_PROGRAM, {
    index: true,
    schema: Schema.program,
    handler: async (id) => {

        const { data: { results } } = await WebClient.get(`/programs/${id}?g=client`);
        return results;
    }
});

exports.fetchPrograms = MiddleEnd.createAction(FETCH_PROGRAMS, {
    index: true,
    schema: [Schema.program],
    handler: async (id) => {

        const { data: { results } } = await WebClient.get(`/programs`);
        return results;
    }
});

exports.editProgram = MiddleEnd.createAction(EDIT_PROGRAM, {
    index: true,
    schema: Schema.program,
    handler: async (programInfo) => {

        const { data: { results } } = await WebClient.post('/programs', programInfo);
        return results;
    }
});

exports.deleteProgram = MiddleEnd.createAction(DELETE_PROGRAM, {
    index: true,
    handler: async (id) => {

        const { data: { results } } = await WebClient.delete(`/programs/${id}`);
        return results;
    },
    after: () => M.dispatch.model.fetchPrograms()
});

exports.archiveProgram = MiddleEnd.createAction(ARCHIVE_PROGRAM, {
    index: true,
    schema: Schema.program,
    handler: async (id) => {

        const { data: { results } } = await WebClient.post(`/programs/archive`, { id });
        return results;
    }
});

exports.uploadEligibility = MiddleEnd.createAction(UPLOAD_ELIGIBILITY, {
    index: true,
    handler: async ({ id, file }) => {

        const { data: { results } } = await WebClient.put(`/eligibility/import/${id}`, file, { headers: { 'x-filename': file.name, 'x-filetype': file.type } });
        return results;
    }
});

exports.fetchEligibilityFiles = MiddleEnd.createAction(FETCH_ELIGIBILITY_FILES, {
    index: true,
    handler: async (programId) => {

        const { data: { results } } = await WebClient.get(`/eligibility/${programId}`);
        return results;
    }
});

exports.fetchEligibilityMapping = MiddleEnd.createAction(FETCH_ELIGIBILITY_MAPPING, {
    index: true,
    handler: async (programId) => {

        const { data: { results } } = await WebClient.get(`/eligibility/mapping/${programId}`);
        return results;
    }
});

exports.updateEligibilityMapping = MiddleEnd.createAction(UPDATE_ELIGIBILTY_MAPPING, {
    index: true,
    handler: async (eligibilityMapping) => {

        const { data: { results } } = await WebClient.post(`/eligibility/mapping`, eligibilityMapping);
        return results;
    }
});

exports.uploadConsent = MiddleEnd.createAction(UPLOAD_CONSENT, {
    index: true,
    handler: async ({ id, file }) => {

        const { data: { results } } = await WebClient.put(`/programs/${id}/consent`, file, { headers: { 'x-filename': file.name, 'x-filetype': file.type } });
        return results;
    }
});

exports.uploadEvents = MiddleEnd.createAction(UPLOAD_EVENTS, {
    index: true,
    handler: async ({ id, file }) => {

        const { data: { results } } = await WebClient.put(`/events/import/${id}`, file, { headers: { 'x-filename': file.name, 'x-filetype': file.type } });
        return results;
    }
});

exports.uploadPDFForm = MiddleEnd.createAction(UPLOAD_PDFFORM, {
    index: true,
    handler: async ({ id, file }) => {

        const { data: { results } } = await WebClient.put(`/programs/${id}/pdf`, file, { headers: { 'x-filename': file.name, 'x-filetype': file.type } });
        return results;
    }
});

exports.uploadCovidResults = MiddleEnd.createAction(UPLOAD_COVIDFORM, {
    index: true,
    handler: async ({ id, file }) => {

        const { data: { results } } = await WebClient.put(`/covid/${id}/results`, file, { headers: { 'x-filename': file.name, 'x-filetype': file.type } });
        return results;
    }
});

exports.uploadCovidRequisition = MiddleEnd.createAction(UPLOAD_COVID_REQUISITION, {
    index: true,
    handler: async ({ id, file }) => {

        const { data: { results } } = await WebClient.put(`/covid/${id}/requisition`, file, { headers: { 'x-filename': file.name, 'x-filetype': file.type } });
        return results;
    }
});

exports.uploadAppointments = MiddleEnd.createAction(UPLOAD_APPOINTMENTS, {
    index: true,
    handler: async ({ id, file }) => {

        const { data: { results } } = await WebClient.put(`/events/${id}/appointments`, file, { headers: { 'x-filename': file.name, 'x-filetype': file.type } });
        return results;
    }
});

exports.fetchScreeningMetrics = MiddleEnd.createAction(FETCH_SCREENING_METRICS, {
    index: true,
    handler: async () => {

        const { data: { results } } = await WebClient.get(`/screening-metrics`);
        return results;
    }
});

exports.updateScreeningMetricsSort = MiddleEnd.createAction(UPDATE_SCREENING_METRICS_SORT, {
    handler: async ({ metrics }) => {

        const { data: { results } } = await WebClient.post('/screening-metrics/sort-order', metrics);
        return results;
    }
});

exports.fetchScreeningCategories = MiddleEnd.createAction(FETCH_SCREENING_CATEGORIES, {
    index: true,
    handler: async () => {

        const { data: { results } } = await WebClient.get(`/screening-metrics/categories`);
        return results;
    }
});

exports.editScreeningMetrics = MiddleEnd.createAction(EDIT_SCREENING_METRICS, {
    index: true,
    handler: async (screeningMetrics) => {

        const { data: { results } } = await WebClient.post('/screening-metrics', screeningMetrics);
        return results;
    },
    after: () => M.dispatch.model.fetchScreeningMetrics()
});

exports.searchPatients = MiddleEnd.createAction(SEARCH_PATIENTS, {
    index: true,
    schema: [Schema.patient],
    handler: async (searchCriteria) => {

        const { data: { results } } = await WebClient.post('/patients/search', searchCriteria);
        return results;
    }
});

exports.fetchPatient = MiddleEnd.createAction(FETCH_PATIENT, {
    index: true,
    schema: Schema.patient,
    handler: async (id) => {

        const { data: { results } } = await WebClient.get(`/patients/${id}`);
        return results;
    }
});

exports.createPatient = MiddleEnd.createAction(CREATE_PATIENT, {
    index: true,
    schema: Schema.patient,
    handler: async (patient) => {

        const { data: { results } } = await WebClient.put(`/patients`, patient);
        return results;
    }
});

exports.updatePatient = MiddleEnd.createAction(UPDATE_PATIENT, {
    index: true,
    schema: Schema.patient,
    handler: async (formPatient) => {

        const patient = internals.nullsToEmptystrings(formPatient);

        const { data: { results } } = await WebClient.post(`/patients`, patient);
        return results;
    }
});

exports.editScreening = MiddleEnd.createAction(EDIT_SCREENING, {
    index: true,
    handler: async (screeningInfo) => {

        const { data: { results } } = await WebClient.post('/screenings', screeningInfo);
        return results;
    },
    after: ({ original: { patientId } }) => M.dispatch.model.fetchPatient(patientId)
});

exports.confirmScreening = MiddleEnd.createAction(CONFIRM_SCREENING, {
    index: true,
    handler: async (screeningInfo) => {

        const { data: { results } } = await WebClient.post('/screenings/confirm', screeningInfo);
        return results;
    }
});

exports.createUnlinkedScreening = MiddleEnd.createAction(CREATE_UNLINKED_SCREENING, {
    index: true,
    handler: async (screeningInfo) => {

        const { data: { results } } = await WebClient.put('/screenings', screeningInfo);
        return results;
    }
});

exports.fetchScreening = MiddleEnd.createAction(FETCH_SCREENING, {
    index: true,
    schema: Schema.screening,
    handler: async (id) => {

        const { data: { results } } = await WebClient.get(`/screenings/${id}`);
        return results;
    }
});

exports.fetchEvents = MiddleEnd.createAction(FETCH_EVENTS, {
    index: true,
    schema: [Schema.event],
    handler: async (id) => {

        const { data: { results } } = await WebClient.get(`/events/program/${id}`);
        return results;
    }
});

exports.fetchAllEvents = MiddleEnd.createAction(FETCH_ALL_EVENTS, {
    index: true,
    schema: [Schema.event],
    handler: async (id) => {

        const { data: { results } } = await WebClient.get(`/events`);
        return results;
    }
});

exports.fetchEvent = MiddleEnd.createAction(FETCH_EVENT, {
    index: true,
    schema: Schema.event,
    handler: async (id) => {

        const { data: { results } } = await WebClient.get(`/events/${id}`);
        return results;
    }
});

exports.fetchAppointment = MiddleEnd.createAction(FETCH_APPOINTMENT, {
    index: true,
    schema: Schema.appointment,
    handler: async (id) => {

        const { data: { results } } = await WebClient.get(`/appointments/${id}`);
        return results;
    }
});

exports.updateAppointment = MiddleEnd.createAction(UPDATE_APPOINTMENT, {
    index: true,
    schema: Schema.appointment,
    handler: async (appointmentInfo) => {

        const { data: { results } } = await WebClient.post(`/appointments`, appointmentInfo);
        return results;
    },
    after: ({ original: { id }, result }) => {

        M.dispatch.model.fetchAppointment(id);

        try {
            M.dispatch.model.fetchPatient(result.entities.appointments[result.result].patientId);
        }
        catch (err) {
            /* no-op */
        }
    }
});

exports.scheduleAppointment = MiddleEnd.createAction(SCHEDULE_APPOINTMENT, {
    index: true,
    schema: Schema.appointment,
    handler: async (appointmentInfo) => {

        const { data: { results } } = await WebClient.post('/appointments/schedule', appointmentInfo);
        return results;
    },
    after: ({ original: { patientId } }) => M.dispatch.model.fetchPatient(patientId)
});

exports.rescheduleAppointment = MiddleEnd.createAction(RESCHEDULE_APPOINTMENT, {
    schema: Schema.appointment,
    handler: async (appointmentInfo) => {

        const { data: { results } } = await WebClient.post('/appointments/reschedule', appointmentInfo);
        return results;
    },
    after: ({ original: { patientId } }) => M.dispatch.model.fetchPatient(patientId)
});

exports.cancelAppointment = MiddleEnd.createAction(CANCEL_APPOINTMENT, {
    schema: Schema.appointment,
    handler: async (appointmentInfo) => {

        const { data: { results } } = await WebClient.post('/appointments/cancel', appointmentInfo);
        return results;
    },
    after: ({ original: { patientId } }) => M.dispatch.model.fetchPatient(patientId)
});

exports.sendAppointmentConfirmation = MiddleEnd.createAction(SEND_CONFIRMATION, {
    handler: async (id) => {

        const { data: { results } } = await WebClient.post('/appointments/send-confirmation', { id });
        return results;
    }
});

exports.editEvent = MiddleEnd.createAction(EDIT_EVENT, {
    index: true,
    schema: Schema.event,
    handler: async (eventInfo) => {

        const { data: { results } } = await WebClient.post('/events', eventInfo);
        return results;
    }
});

exports.deleteEvent = MiddleEnd.createAction(DELETE_EVENT, {
    handler: async (id, programId) => {

        const { data: { results } } = await WebClient.delete(`/events/${id}`);
        return results;
    },
    after: ({ original: [id, programId] }) => M.dispatch.model.fetchEvents(programId)
});

exports.sendEventCommunication = MiddleEnd.createAction(SEND_EVENT_COMMUNICATION, {
    handler: async (communication) => {

        const { data: { results } } = await WebClient.post('/events/communicate', communication);
        return results;
    }
});

exports.fetchClientReport = MiddleEnd.createAction(FETCH_CLIENT_REPORT, {
    index: true,
    handler: async (eventIds) => {

        const { data: { results } } = await WebClient.post('/reports/report-card', eventIds);
        return results;
    }
});

exports.fetchParticipationReport = MiddleEnd.createAction(FETCH_PARTICIPATION_REPORT, {
    index: true,
    handler: async (id) => {

        const { data: { results } } = await WebClient.post('/reports/participation', { programId: id });
        return results.sort((a, b) => new Date(a.date) - new Date(b.date));
    }
});

exports.fetchProgramScreeningsWithResults = MiddleEnd.createAction(FETCH_SCREENINGS_WITH_RESULTS, {
    index: true,
    handler: async (id) => {

        const { data: { results } } = await WebClient.get(`/programs/${id}/screenings/with-results`);

        return results;
    }
});

exports.sendResultsEmails = MiddleEnd.createAction(SEND_RESULTS_EMAILS, {
    handler: async ({ programId: id, screeningIds }) => {

        const { data: { results } } = await WebClient.post(`/programs/${id}/screenings/send-results-emails`, {
            screeningIds
        });

        return results;
    },
    after: async ({ original: { programId } }) => {

        return await M.dispatch.model.fetchProgramScreeningsWithResults(programId);
    }
});

exports.fetchAllAppointmentsForEvent = MiddleEnd.createAction(FETCH_ALL_APPOINTMENTS_FOR_EVENT, {
    index: true,
    schema: [Schema.appointment],
    handler: async (id) => {

        const { data: { results } } = await WebClient.get(`/events/${id}/appointments`);
        return results;
    }
});

exports.sendResultsEmails = MiddleEnd.createAction(SEND_RESULTS_EMAILS, {
    handler: async ({ programId: id, screeningIds }) => {

        const { data: { results } } = await WebClient.post(`/programs/${id}/screenings/send-results-emails`, {
            screeningIds
        });

        return results;
    },
    after: async ({ original: { programId } }) => {

        return await M.dispatch.model.fetchProgramScreeningsWithResults(programId);
    }
});

exports.purgeData = MiddleEnd.createAction(PURGE_DATA, {
    handler: async () => {

        const { data: { results } } = await WebClient.post(`/settings/purge`);

        return results;
    }
});

exports.upsertForm = MiddleEnd.createAction(UPSERT_FORM, {
    handler: async (form) => {

        const { data: { results } } = await WebClient.post(`/forms`, form);

        return results;
    }
});

exports.fetchForms = MiddleEnd.createAction(FETCH_FORMS, {
    index: true,
    schema: [Schema.form],
    handler: async () => {

        const { data: { results } } = await WebClient.get(`/forms`);

        return results;
    }
});

exports.fetchForm = MiddleEnd.createAction(FETCH_FORM, {
    handler: async (id) => {

        const { data: { results } } = await WebClient.get(`/forms/${id}`);

        return results;
    }
});

exports.deleteForm = MiddleEnd.createAction(DELETE_FORM, {
    handler: async (id) => {

        const { data: { results } } = await WebClient.delete(`/forms/${id}`);

        return results;
    },
    after: () => M.dispatch.model.fetchForms()
});

exports.upsertFormSubmission = MiddleEnd.createAction(UPSERT_FORMSUBMISSION, {
    handler: async (form) => {

        const { data: { results } } = await WebClient.post(`/form-submission`, form);

        return results;
    }
});

exports.fetchFormSubmissions = MiddleEnd.createAction(FETCH_FORMSUBMISSIONS, {
    index: true,
    schema: [Schema.form],
    handler: async () => {

        const { data: { results } } = await WebClient.get(`/form-submission`);

        return results;
    }
});

exports.fetchFormSubmission = MiddleEnd.createAction(FETCH_FORMSUBMISSION, {
    handler: async (params) => {

        const { data: { results } } = await WebClient.get(`/form-submission?${QueryString.encode(params)}`);

        return results;
    }
});

exports.upsertForm = MiddleEnd.createAction(UPSERT_FORM, {
    handler: async (form) => {

        const { data: { results } } = await WebClient.post(`/forms`, form);

        return results;
    }
});

exports.fetchForms = MiddleEnd.createAction(FETCH_FORMS, {
    index: true,
    schema: [Schema.form],
    handler: async () => {

        const { data: { results } } = await WebClient.get(`/forms`);

        return results;
    }
});

exports.fetchForm = MiddleEnd.createAction(FETCH_FORM, {
    handler: async (id) => {

        const { data: { results } } = await WebClient.get(`/forms/${id}`);

        return results;
    }
});

exports.deleteForm = MiddleEnd.createAction(DELETE_FORM, {
    handler: async (id) => {

        const { data: { results } } = await WebClient.delete(`/forms/${id}`);

        return results;
    },
    after: () => M.dispatch.model.fetchForms()
});

internals.nullsToEmptystrings = (obj) => {

    return Object.entries(obj).reduce((collector, [key, val]) => ({
        ...collector,
        [key]: val === null ? '' : val
    }), obj);
};
