const Auth = require('./auth');
const Layout = require('../containers/Layout');
const EmptyLayout = require('../components/EmptyLayout');
const PatientLayout = require('../containers/PatientLayout');
const ManagerLayout = require('../containers/ManagerLayout');
const NotFoundPage = require('../components/NotFoundPage');
const NotFoundHelpers = require('./helpers/not-found');
const InactiveHelper = require('./helpers/inactive');
const HomePage = require('./home/components/HomePage');
const LoginPage = require('./login/containers/LoginPage');
const CreateClientPage = require('./create-client/containers/CreateClientPage');
const CreateUserPage = require('./create-user/containers/CreateUserPage');
const EditUserPage = require('./edit-user/containers/EditUserPage');
const SearchUsersPage = require('./search-users/containers/SearchUsersPage');
const EditClientPage = require('./edit-client/containers/EditClientPage');
const SearchClientsPage = require('./search-clients/containers/SearchClientsPage');
const CreateProgramPage = require('./create-program/containers/CreateProgramPage');
const EditProgramPage = require('./edit-program/containers/EditProgramPage');
const SearchProgramsPage = require('./search-programs/containers/SearchProgramsPage');
const ForgotPasswordPage = require('./forgot-password/containers/ForgotPasswordPage');
const ResetPasswordPage = require('./reset-password/containers/ResetPasswordPage');
const SetupFormsPage = require('./setup-forms/containers/SetupFormsPage');
const SetupSmsPage = require('./setup-sms/containers/SetupSmsPage');
const SetupEmailPage = require('./setup-email/containers/SetupEmailPage');
const SetupPatientSignupPage = require('./setup-patient-signup/containers/SetupPatientSignupPage');
const SetupScreeningMetricsPage = require('./setup-screening-metrics/containers/SetupScreeningMetricsPage');
const SearchPatientsPage = require('./search-patients/containers/SearchPatientsPage');
const PatientScreeningPage = require('./patient-screening/containers/PatientScreeningPage');
const UnlistedPatientScreeningPage = require('./unlisted-patient-screening/containers/UnlistedPatientScreeningPage');
const PatientPortalPage = require('./patient-portal/containers/PatientPortalPage');
const PatientSchedulePage = require('./patient-schedule/containers/PatientSchedulePage');
const PatientScheduleSuccessPage = require('./patient-schedule-success/containers/PatientScheduleSuccessPage');
const PatientCovidPage = require('./patient-covid/containers/PatientCovidPage');
const PatientScheduleCancelPage = require('./patient-schedule-cancel/components/PatientScheduleCancelPage');
const ScreeningReport = require('./screening-report/containers/ScreeningReport');
const ClientReport = require('./client-report/containers/ClientReport');
const SearchEventsPage = require('./search-events/containers/SearchEventsPage');
const SearchAllEventsPage = require('./search-events/containers/SearchAllEventsPage');
const CreateEventPage = require('./create-event/containers/CreateEventPage');
const EditEventPage = require('./create-event/containers/EditEventPage');
const ReportingPage = require('./reporting/containers/ReportingPage');
const EventDetailPage = require('./event-detail/containers/EventDetailPage');
const ParticipationReportPage = require('./participation-report/containers/ParticipationReportPage');
const EditEligibilityMappingPage = require('./edit-eligibility-mapping/containers/EditEligibilityMapping');
const ScreeningSurveyPage = require('./screening-survey/containers/ScreeningSurveyPage');
const ManageScreeningMetricsPage = require('./manage-screening-metrics/containers/ManageScreeningMetricsPage');
const CovidLabel = require('./covid-label/containers/CovidLabel');
const EmailScreeningResultsPage = require('./email-results/containers/EmailScreeningResults');
const AllCovidLabelsForEvent = require('./all-covid-labels-for-event/containers/AllCovidLabelsForEvent');
const SettingsPage = require('./settings/containers/SettingsPage');
const SearchFormsPage = require('./search-forms/containers/SearchFormsPage');
const FormBuilderPage = require('./form-builder/containers/FormBuilderPage');
const RegistrationFormPage = require('./registration-form/containers/RegistrationFormPage');

const { MANAGER_URL_PART } = require('../constants');

module.exports = [
    {
        path: '/login',
        component: EmptyLayout,
        childRoutes: [
            {
                path: '/',
                component: LoginPage,
                exact: true
            }
        ]
    },
    {
        path: '/screening/:id',
        component: Auth.authenticate(InactiveHelper(ScreeningReport)),
        exact: true
    },
    {
        path: '/client-report',
        component: Auth.authenticate(InactiveHelper(ClientReport)),
        exact: true
    },
    {
        path: '/covid-label/:appointmentId',
        component: Auth.authenticate(InactiveHelper(CovidLabel)),
        exact: true
    },
    {
        path: '/covid-label/all-for-event/:eventId',
        component: Auth.authenticate(InactiveHelper(AllCovidLabelsForEvent)),
        exact: true
    },
    {
        path: '/forgot-password',
        component: EmptyLayout,
        childRoutes: [
            {
                path: '/',
                component: ForgotPasswordPage,
                exact: true
            }
        ]
    },
    {
        path: '/reset-password',
        component: EmptyLayout,
        childRoutes: [
            {
                path: '/',
                component: ResetPasswordPage,
                exact: true
            }
        ]
    },
    {
        path: '/portal/:id',
        component: PatientLayout,
        childRoutes: [
            {
                path: '/',
                component: PatientPortalPage,
                exact: true
            },
            {
                path: '/schedule/:patientId',
                component: PatientSchedulePage,
                exact: true
            },
            {
                path: '/success/:appointmentId',
                component: PatientScheduleSuccessPage,
                exact: true
            },
            {
                path: '/covid/:patientId',
                component: PatientCovidPage,
                exact: true
            },
            {
                path: '/cancel',
                component: PatientScheduleCancelPage,
                exact: true
            },
            {
                path: '/registration/:patientId',
                component: RegistrationFormPage,
                exact: true
            }
        ]
    },
    {
        path: `${MANAGER_URL_PART}/portal/:id`,
        component: PatientLayout,
        childRoutes: [
            {
                path: '/',
                component: PatientPortalPage,
                exact: true
            },
            {
                path: '/schedule/:patientId',
                component: PatientSchedulePage,
                exact: true
            },
            {
                path: '/success/:appointmentId',
                component: PatientScheduleSuccessPage,
                exact: true
            },
            {
                path: '/covid/:patientId',
                component: PatientCovidPage,
                exact: true
            },
            {
                path: '/cancel',
                component: PatientScheduleCancelPage,
                exact: true
            },
            {
                path: '/registration/:patientId',
                component: RegistrationFormPage,
                exact: true
            }
        ]
    },
    {
        path: '/screener/',
        component: Auth.authenticate(InactiveHelper(NotFoundHelpers.withNotFoundPage(Layout, NotFoundPage))),
        childRoutes: [
            {
                path: '/',
                component: SearchPatientsPage,
                exact: true
            },
            {
                path: '/patient/:id/screening/',
                component: PatientScreeningPage,
                exact: true
            },
            {
                path: '/programs/:id/screening/new',
                component: UnlistedPatientScreeningPage,
                exact: true
            }
        ]
    },
    {
        path: '/survey/',
        component: Auth.authenticate(InactiveHelper(NotFoundHelpers.withNotFoundPage(EmptyLayout, NotFoundPage))),
        childRoutes: [
            {
                path: '/:id',
                component: ScreeningSurveyPage,
                exact: true
            }
        ]
    },
    {
        path: '/manager/',
        component: Auth.authenticate(InactiveHelper(NotFoundHelpers.withNotFoundPage(ManagerLayout, NotFoundPage))),
        childRoutes: [
            {
                path: '/',
                component: SearchAllEventsPage,
                exact: true
            },
            {
                path: '/event/:id/',
                component: EventDetailPage,
                exact: true
            }
        ]
    },
    {
        path: '/programs/',
        component: Auth.authenticate(InactiveHelper(NotFoundHelpers.withNotFoundPage(Layout, NotFoundPage))),
        childRoutes: [
            {
                path: '/new',
                component: CreateProgramPage,
                exact: true
            },
            {
                path: '/:id/edit',
                component: EditProgramPage,
                exact: true
            },
            {
                path: '/search',
                component: SearchProgramsPage,
                exact: true
            },
            {
                path: '/:id/setup/forms',
                component: SetupFormsPage,
                exact: true
            },
            {
                path: '/:id/setup/eligibility',
                component: EditEligibilityMappingPage,
                exact: true
            },
            {
                path: '/:id/setup/sms',
                component: SetupSmsPage,
                exact: true
            },
            {
                path: '/:id/setup/email',
                component: SetupEmailPage,
                exact: true
            },
            {
                path: '/:id/setup/screening-metrics',
                component: SetupScreeningMetricsPage,
                exact: true
            },
            {
                path: '/:id/setup/patient-signup-text',
                component: SetupPatientSignupPage,
                exact: true
            },
            {
                path: '/:id/screening/search',
                component: SearchPatientsPage,
                exact: true
            },
            {
                path: '/:id/screening/new',
                component: UnlistedPatientScreeningPage,
                exact: true
            },
            {
                path: '/:id/events/new',
                component: CreateEventPage,
                exact: true
            },
            {
                path: '/:id/events/search',
                component: SearchEventsPage,
                exact: true
            },
            {
                path: '/:id/events/:eventId/edit',
                component: EditEventPage,
                exact: true
            },
            {
                path: '/:id/reporting',
                component: ReportingPage,
                exact: true
            },
            {
                path: '/:id/participation-report',
                component: ParticipationReportPage,
                exact: true
            },
            {
                path: '/:id/email-results',
                component: EmailScreeningResultsPage,
                exact: true
            }
        ]
    },
    {
        path: '/clients/',
        component: Auth.authenticate(InactiveHelper(NotFoundHelpers.withNotFoundPage(Layout, NotFoundPage))),
        childRoutes: [
            {
                path: '/new',
                component: CreateClientPage,
                exact: true
            },
            {
                path: '/:id/edit',
                component: EditClientPage,
                exact: true
            },
            {
                path: '/search',
                component: SearchClientsPage,
                exact: true
            }
        ]
    },
    {
        path: '/users/',
        component: Auth.authenticate(InactiveHelper(NotFoundHelpers.withNotFoundPage(Layout, NotFoundPage))),
        childRoutes: [
            {
                path: '/new',
                component: CreateUserPage,
                exact: true
            },
            {
                path: '/:id/edit',
                component: EditUserPage,
                exact: true
            },
            {
                path: '/search',
                component: SearchUsersPage,
                exact: true
            }
        ]
    },
    {
        path: '/forms/',
        component: Auth.authenticate(InactiveHelper(NotFoundHelpers.withNotFoundPage(Layout, NotFoundPage))),
        childRoutes: [
            {
                path: '/new',
                component: FormBuilderPage,
                exact: true
            },
            {
                path: '/:id/edit',
                component: FormBuilderPage,
                exact: true
            },
            {
                path: '/',
                component: SearchFormsPage,
                exact: true
            }
        ]
    },
    {
        path: '/',
        // TODO: Should the 404 page use the default layout?
        component: Auth.authenticate(InactiveHelper(NotFoundHelpers.withNotFoundPage(Layout, NotFoundPage))),
        childRoutes: [
            {
                path: '/',
                component: HomePage,
                exact: true
            },
            {
                path: '/screening-metrics',
                component: ManageScreeningMetricsPage,
                exact: true
            },
            {
                path: 'patient/:id/screening',
                component: PatientScreeningPage,
                exact: true
            },
            {
                path: '/settings',
                component: SettingsPage,
                exact: true
            },
            NotFoundHelpers.CatchAllRoute
        ]
    }
];
